exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-work-ecycle-tsx": () => import("./../../../src/pages/work/ecycle.tsx" /* webpackChunkName: "component---src-pages-work-ecycle-tsx" */),
  "component---src-pages-work-kittycatchat-tsx": () => import("./../../../src/pages/work/kittycatchat.tsx" /* webpackChunkName: "component---src-pages-work-kittycatchat-tsx" */),
  "component---src-pages-work-metrolinx-tsx": () => import("./../../../src/pages/work/metrolinx.tsx" /* webpackChunkName: "component---src-pages-work-metrolinx-tsx" */),
  "component---src-pages-work-template-tsx": () => import("./../../../src/pages/work/template.tsx" /* webpackChunkName: "component---src-pages-work-template-tsx" */)
}

